export const JS_SRC = "https://accounts.google.com/gsi/client";
export const SCRIPT_ID = "google-login";
export const PREVENT_CORS_URL: string = "https://cors.bridged.cc";
export const _window = window as any;
export const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const scope = "https://www.googleapis.com/auth/userinfo.profile";
export const prompt = "select_account";
export const ux_mode = "popup";
export const login_hint = "";
export const access_type = "online";
export const cookie_policy = "single_host_origin";
export const hosted_domain = "";
export const discoveryDocs = "";
export const fetch_basic_profile = true;
export const isOnlyGetToken = false;
export const GOOGLE_OAUTH_URL =
  "https://www.googleapis.com/oauth2/v1/userinfo?alt=json";
